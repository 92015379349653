<template>
  <Spinner v-if="showSpinner" isSmall :spinnerStyle="'width: 28px; height: 28px'"></Spinner>
  <div v-else v-tooltip.top="RequestPositionStates.find(state => state.num === position.state)?.label[$i18n.locale]"
       @click="toggleRequestStateOverlay($event, position.id)"
       class="request-state-wrapper"
       @contextmenu="onRequestPositionStateClick($event)">
    <div class="request-state request-state--big" :class="RequestPositionStates.find(pos => pos.num === position.state)?.classData">
      <span v-if="position.request_state_history?.length">{{ position.request_state_history.length }}</span>
    </div>
    <OverlayPanel :ref="'rph-' + (isOrderModal ? 'order-modal-' : (isSalesInvoiceModal ? 'sales-invoice-modal-' : '')) + position.id">
      <div v-if="position.request_state_history?.length">
        <div v-for="(data, index) of [...position.request_state_history].sort((a, b) => b.id - a.id)" :key="index">
          <div class="p-d-flex p-ai-center p-jc-between">
            <div class="p-d-flex p-ai-center">
              <div class="state-badge" :class="RequestPositionStates.find(state => state.num === data.new)?.classData || ''">{{ RequestPositionStates.find(state => state.num === data.new)?.label[$i18n.locale] }}</div>
            </div>
            <div class="p-ml-3 p-mr-3">
              <span v-if="data.created_at">{{ formatDate(data.created_at) }},</span> <span v-if="data.creator && data.creator.first_name">{{ data.creator.first_name }}</span> <span v-if="data.creator && data.creator.last_name">{{ data.creator.last_name }}</span>
            </div>
          </div>
          <br v-if="index === position.request_state_history.length - 1">
          <div class="p-d-flex p-ai-center p-jc-between" v-if="index === position.request_state_history.length - 1">
            <div class="p-d-flex p-ai-center">
              <div class="state-badge"
                   :class="RequestPositionStates.find(state => state.num === position.request_state_history[0]?.old)?.classData || ''">
                {{ RequestPositionStates.find(state => state.num === position.request_state_history[0]?.old)?.label[$i18n.locale] }}
              </div>
            </div>
            <div class="p-ml-3 p-mr-3">
              <span v-if="position.created_at">{{ formatDate(position.created_at) }},</span> <span v-if="position.creator && position.creator.first_name">{{ position.creator.first_name }}</span> <span v-if="position.creator && position.creator.last_name">{{ position.creator.last_name }}</span>
            </div>
          </div>
          <br v-if="index !== position.request_state_history.length - 1">
        </div>
      </div>
      <div v-else>{{ $t('No data available' )}}</div>
    </OverlayPanel>
<!--    @leaf-click="changeRequestState($event)"-->
<!--    @leaf-click="onChangeRequestState($event, position.state, position)"-->
    <RequestStatesContextMenu :isOrderModal="isOrderModal"
                              :isSalesInvoiceModal="isSalesInvoiceModal"
                              @leaf-click="onChangeRequestState($event, position.state, position)"
                              ref="requestStatesMenuAccordion"
                              :showSpinner="showSpinner"
                              :notOrderedStates="computedNotOrderedStates"
                              :orderedStates="orderedStates"/>
    <!--        :model="computedRequestPositionStates"/>-->
  </div>
  <!--  <div v-else class="request-state request-state&#45;&#45;big"></div>-->
</template>
<script>
import RequestPositionStates from '@/translations/states/RequestPositionStates'
// import RequestPositionContextMenu from '@/components/RequestPositionContextMenu'
// import RequestPositionContextMenu from "@/pages/orders/components/RequestPositionContextMenu";
import httpClient from "@/services/http.services";
// import httpMixins from "@/mixins/httpMixins";
import constants from '@/constants'
import settings from "@/settings";
import formatMixins from "@/mixins/formatMixins";
import showErrorsMixins from "@/mixins/showErrorsMixins";
import RequestStatesContextMenu from "@/pages/orders/components/RequestStatesContextMenu";

export default {
  components: { RequestStatesContextMenu },
  mixins: [ formatMixins, showErrorsMixins ],
  emits: ['change-state', 'actualize-request-data'],
  name: 'RequestPositionStateButton',
  props: {
    orderIsArchived: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    orderId: Number,
    position: Object,
    isOrderModal: {
      type: Boolean,
      default: false
    },
    isSalesInvoiceModal: {
      type: Boolean,
      default: false
    },
    // disabled: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      showSpinner: false,
      // positionToUpdate: null,
      RequestPositionStates,
      constants,
      // notOrderedStates: this.position.fixed_qty || this.position.reduced_qty ? null : RequestPositionStates.filter(s => s.num < 4),
      orderedStates: RequestPositionStates.filter(s => s.num >= 4),
    }
  },
  methods: {
    // onRequestPositionStateClick(event, position) {
    // onRequestPositionStateClick(event) {
    //   if (this.isDisabled ||
    //       position.status === 0 ||
    //       (this.$store.state.user.role !== constants.userRoles.superAdmin && this.$store.state.user.role !== constants.userRoles.admin)
    //       || (this.orderIsArchived && (this.$store.state.user.role !== constants.userRoles.superAdmin && this.$store.state.user.role !== constants.userRoles.admin))) {
    //     event.preventDefault()
    //     return false
    //   }
    //   this.$refs.requestStatesMenuAccordion.show(event);
    // },
    // onRequestPositionStateClick(event, position) {
    //   const userIsAdmin = this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin
    //   if (this.isDisabled ||
    //       (position.status === 0 && !userIsAdmin) ||
    //       (this.orderIsArchived && !userIsAdmin)) {
    //     event.preventDefault()
    //     return false
    //   }
    //   this.$refs.requestStatesMenuAccordion.show(event);
    // },
    onRequestPositionStateClick(event) {
      // const userIsAdmin = this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin
      // if (this.isDisabled ||
      //     (this.position.status === 0 && !userIsAdmin) ||
      //     (this.orderIsArchived && !userIsAdmin)) {
      //   event.preventDefault()
      //   return false
      // }
      const userIsAdmin = this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin
      if (this.isDisabled ||
          (this.position.status === 0 && !userIsAdmin) ||
          (this.orderIsArchived && !userIsAdmin)) {
        event.preventDefault()
        return false
      }
      this.$refs.requestStatesMenuAccordion.show(event);
      // if (!this.position.isNew) this.getServerPositionData()
    },
    // async getServerPositionData() {
    //   // console.log('getPositionData')
    //   if (!this.position) return
    //   this.showSpinner = true
    //   try {
    //     const { status, data } = await httpClient({
    //       url: 'order-position/get-request-data',
    //       method: 'post',
    //       data: {
    //         id: this.position.id,
    //       }
    //     })
    //     // console.log(data)
    //
    //     if (status === 200 && data?.success) {
    //       // this.$emit('update-position', {serverPositionData : data, orderPosition: this.position});
    //       // const state = data.state
    //       console.log(data)
    //       // this.notOrderedStates = data.fixedQty || data.reducedQty ? null : RequestPositionStates.filter(s => s.num < 4)
    //
    //
    //       // if (this.position.status && this.position.state < 4 && maxQtyToDecrease >= this.position.qty) { //Delete action
    //       //   const deleteAction = RequestActions.find(a => a.actionType === 7)
    //       //   if (deleteAction) {
    //       //     this.actions.push(deleteAction)
    //       //   }
    //       // }
    //     } else if (data?.error) {
    //       console.log(data.error)
    //       this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
    //     } else {
    //       this.showUnknownError()
    //     }
    //   } catch(err) {
    //     this.showError(err)
    //   } finally {
    //     this.showSpinner = false
    //   }
    // },
    async onChangeRequestState(newState, oldState, position) {
      if (position.status === 0 &&
          (this.$store.state.user.role !== constants.userRoles.superAdmin &&
           this.$store.state.user.role !== constants.userRoles.admin &&
           this.$store.state.user.role !== constants.userRoles.accountant)) {
          // newState === oldState) {
        return false
      }

      // if (newState === oldState) {
      //   return
      // }

      // position.isUpdated = !position.isNew  // Return if necessary

      // if (position.isNew) {
      //   console.log(1)
      //   if (position.id) {
      //     console.log(2)
      //     position.isUpdated = true
      //   }
      // } else {
      // if (!position.isNew && !this.isModal) {

      // if (!this.isModal) {
      if (position.isNew) {
        position.state = newState
      } else {
        this.changeRequestState(newState, oldState, position)
      }
      // else if (position.id && newState > 3) {
      //   this.checkRequestOrderedQty(newState, oldState, position)
      // }
    },
    async changeRequestState(newState, oldState, position) {
      // async changeRequestState(newState) {
      // if (!newState || this.position.state === newState) return
      if (!newState) return
      this.showSpinner = true

      // console.log(oldState, position)

      const obj = {
        positionId: this.position.id,
        positionHistoryLength: this.position.history?.length,
        positionRequestStateHistoryLength: this.position.request_state_history?.length,
        newState}
      try {
        const { status, data } = await httpClient.post('order-position/change-state', obj)
        // console.log(data)
        if (status === 201 && data && (data.success || data.already_ordered)) {
          // if (data.permission_denied) {
          //   this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Permission denied', life: settings.toastLife})
          //   // position.state = oldState
          //   return false
          // }
          // const actualRequestStateHistoryLength = position.request_state_history?.length

          // if (this.isOrderModal && this.SalesInvoiceModal) {
          //
          // } else {
          //
          // }
          position.state = data.state || +data.state === 0 ? +data.state : position.state
          position.status = data.status || +data.status === 0 ? data.status : position.status
          position.reduced_qty = data.reducedQty || +data.reducedQty === 0 ? +data.reducedQty : position.reduced_qty
          position.fixed_qty = data.fixedQty || +data.fixedQty === 0 ? +data.fixedQty : position.fixed_qty
          if (data.request_state_history) {
            position.request_state_history = data.request_state_history
          }
          if (data.position_history) {
            position.history = data.position_history
          }
          position.changedMultipleState = false
          position.originalState = position.state
          // if (data.success) {
            // if (position.request_state_history?.length > actualRequestStateHistoryLength) {
            //   this.$emit('change-state', { positionId: this.position.id, serverPositionData: data })
            //   this.$emit('change-state', { serverPositionData : data, orderPosition: this.position }) //For order positions table
            // }
          // } else
          if (data.already_ordered) {
            this.$toast.add({severity:'error', detail: 'Request has already ordered', life: settings.toastLife});
          }
          this.$emit('actualize-request-data', {serverPositionData : data, orderPosition: this.position, action: 'change-state'});
        } else if (data?.error) {
          console.log(data.error)
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
        } else {
          this.showUnknownError()
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.showSpinner = false
      }
    },
    // async checkPositionState(position) {
    //   // console.log('checkPositionState')
    //   this.showSpinner = true
    //   try {
    //     const { status, data } = await httpClient.post('order-position/get-request-data', { id: position.id })
    //     if (status === 200 && data?.success) {
    //       // const qty = data.qty ? +data.qty : 0
    //       // const state = data.state ? +data.state : 0
    //       const fixedQty = data.fixedQty ? +data.fixedQty : 0
    //       const reducedQty = data.reducedQty ? +data.reducedQty : 0
    //       // const reducedWithoutChangedQty = data.reduced_without_changed_qty ? +data.reduced_without_changed_qty : 0
    //
    //       position.fixed_qty = fixedQty
    //       position.reduced_qty = reducedQty
    //       // position.qty = qty
    //       // position.state = state
    //
    //       if (fixedQty || reducedQty) {
    //         return { success: false }
    //       } else {
    //         return { success: true }
    //       }
    //     }
    //   } catch(err) {
    //     this.showError(err)
    //   } finally {
    //     this.showSpinner = false
    //   }
    // },
    toggleRequestStateOverlay(event, id) {
      if (!event || !id) return false
      this.$refs['rph-' + (this.isOrderModal ? 'order-modal-' : (this.isSalesInvoiceModal ? 'sales-invoice-modal-' : '')) + id.toString()].toggle(event);
    },
  },
  computed: {
    computedNotOrderedStates() {
      const notOrderedStates = RequestPositionStates.filter(item => item.num < 4 && item.num !== 2)
      // if ((this.$store.state.user.role === this.constants.userRoles.superAdmin ||
      //     this.$store.state.user.role === this.constants.userRoles.admin ||
      //     this.$store.state.user.role === this.constants.userRoles.accountant))
      //     // && (!+this.position.fixed_qty && !+this.position.reduced_qty))
      // {
      //   return notOrderedStates
      // } else if (this.$store.state.user.role === this.constants.userRoles.moderator) {
      //   if ((!+this.position.fixed_qty || this.position.isNew || this.position.state === 1 || this.position.state === 3)
      //       && !+this.position.reduced_qty) {
      //     return notOrderedStates
      //   } else {
      //     return null
      //   }
      // } else {
      //   return null
      // }
      if ((this.position.fixed_qty && +this.position.fixed_qty) || (this.position.reduced_qty && +this.position.reduced_qty)) {
        return null
      } else {
        return notOrderedStates
      }

      // this.position.fixed_qty || this.position.reduced_qty ? null : RequestPositionStates.filter(s => s.num < 4)
    },
    computedOrderedStates() {
      if (this.$store.state.user.role === this.constants.userRoles.superAdmin ||
          this.$store.state.user.role === this.constants.userRoles.admin ||
          this.$store.state.user.role === this.constants.userRoles.accountant ||
          this.$store.state.user.role === this.constants.userRoles.moderator) {
        return RequestPositionStates.filter(item => item.num >= 4)
      } else {
        return null
      }
    }
  }
}
</script>
<style lang="scss">
.request-state-wrapper {
  display: block;
  //padding: 6px;
  cursor: pointer;
  //border-radius: 2px;
  //min-width: 23px;
  &:hover {
    opacity: 0.7;
  }
}
.request-state {
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 2px;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  font-size: 0.9em;
}
.request-state--big {
  height: 30px;
  width: 30px;
}
.request-state--new {
  background-color: #C8E6C9;
}
.request-state--сanceled {
  background-color: #FFCDD2;
}
.request-state--to-order {
  background-color: #FEEDAF;
}
.request-state--ordered {
  //background-color: #a2d1ff;
  background-color: #a2d1ff;
}
.request-state--waiting-delivery {
  background-color: #ffcca6;
}
.request-state--need-to-deliver {
  background-color: #c6cbff;
}
.request-state--delivered {
  //background-color: #c9eefb;
  background-color: #9ca2ff;
}
</style>